<template>
  <div
    id="analyse"
    :style="`background-color: ${appThemeConfig.appBgColor}; margin-top: ${
      isApp ? systemBarHeight : 0
    }px`"
  >
    <analyse-stastics-app-header ref="detailAppHead">
    </analyse-stastics-app-header>
    <div
      id="analyse-scroll"
      class="analyse-scroll show-scroll"
      :style="`height:${
        isApp ? screenHeight - systemBarHeight - 56 : screenHeight - 56
      }px;`"
    >
      <van-pull-refresh
        style="min-height: 100%"
        v-model="isRefreshing"
        @refresh="onRefresh"
      >
        <div class="analyse-content pt-5">
          <seven-days class="mb-5" :stasticData="stasticData"></seven-days>

          <v-lazy
            v-model="chartList[2].lazy"
            :options="{
              threshold: 0.5,
            }"
            min-height="250"
            transition="fade-transition"
          >
            <reacent-time-chart class="my-15 mx-2"></reacent-time-chart>
          </v-lazy>
          <v-lazy
            v-model="chartList[0].lazy"
            :options="{
              threshold: 0.5,
            }"
            min-height="250"
            transition="fade-transition"
          >
            <reacent-words-chart class="my-15 mx-2"></reacent-words-chart>
          </v-lazy>
          <v-lazy
            v-model="chartList[1].lazy"
            :options="{
              threshold: 0.5,
            }"
            min-height="250"
            transition="fade-transition"
          >
            <reacent-class-chart class="my-15 mx-2"></reacent-class-chart>
          </v-lazy>

          <div class="footer-takeplace"></div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { buttonVibrate, setSystemColor } from "@/mixin/mixin";
import { isEmpty } from "@/utils/common";
// eslint-disable-next-line no-unused-vars
import $api from "@/api/api";
import AnalyseStasticsAppHeader from "@/views/analyseStastics/components/analyseStasticsAppHeader";
import SevenDays from "@/views/analyseStastics/components/sevenDays";

export default {
  name: "analyseStasticsIndex",
  mixins: [setSystemColor, buttonVibrate],
  components: {
    ReacentTimeChart: () =>
      import("@/views/analyseStastics/components/reacentTimeChart"),
    ReacentClassChart: () =>
      import("@/views/analyseStastics/components/reacentClassChart"),
    ReacentWordsChart: () =>
      import("@/views/analyseStastics/components/reacentWordsChart"),
    SevenDays,
    AnalyseStasticsAppHeader,
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      appTheme: "appTheme",
      appThemeConfig: "appThemeConfig",
      systemBarHeight: "systemBarHeight",
      screenHeight: "screenHeight",
    }),
  },
  data: () => ({
    isLoading: true,
    isRefreshing: false,
    firstCreat: true,
    lazy: false,
    stasticData: [
      {
        date: "2020-03-26",
        gole: 55,
        time: 60,
      },
      {
        date: "2020-03-25",
        gole: 25,
        time: 60,
      },
      {
        date: "2020-03-24",
        gole: 45,
        time: 60,
      },
      {
        date: "2020-03-23",
        gole: 55,
        time: 60,
      },
      {
        date: "2020-03-22",
        gole: 95,
        time: 60,
      },
      {
        date: "2020-03-21",
        gole: 15,
        time: 60,
      },
      {
        date: "2020-03-20",
        gole: 100,
        time: 60,
      },
    ],
    chartList: [{}, {}, {}],
  }),
  methods: {
    isEmpty,
    onRefresh() {
      // 调接口
      this.isRefreshing = false;
    },

    init() {
      this.$store.dispatch("setShowSysAppTab", false);
      this.$store.dispatch("setAppSystemBarColor", "#eaf5f5");
      // 调接口
    },
  },
  created() {
    console.info("analyseStastics组件被创建");
    this.init();
  },
  mounted() {
    console.info("analyseStastics组件被挂载");
  },
  activated() {
    console.info("analyseStastics组件被激活");
    if (this.firstCreat) {
      this.firstCreat = false;
    } else {
      this.init();
    }
  },
  deactivated() {
    console.info("analyseStastics组件被暂停");
  },
};
</script>

<style lang="scss" scoped>
#analyse {
  height: 100%;
  overflow: hidden;
  .analyse-scroll {
    overflow-y: auto;

    .analyse-content {
      width: 100%;
      max-width: 100%;
      min-height: 90vh;
      padding: 0 0.5rem;
      analyse-wrap: break-analyse;

      .footer-takeplace {
        width: 100%;
        height: 7.5rem;
      }
    }
  }
}
</style>
