<template>
  <v-app-bar
    :color="appThemeConfig.headColor"
    :dark="appThemeConfig.dark"
    elevate-on-scroll
    scroll-target="#analyse-scroll"
    class="app-bar rounded-b-lg"
  >
    <v-btn icon @click="goBack">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <v-app-bar-title style="font-size: 16px; color: #7a7a7a"
      >学习情况分析</v-app-bar-title
    >

    <v-progress-linear
      :active="isLoading"
      :indeterminate="true"
      height="2"
      absolute
      bottom
      color="deep-purple accent-1"
    ></v-progress-linear>

    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import { buttonVibrate } from "@/mixin/mixin";

export default {
  name: "analyseStasticsAppHeader",
  mixins: [buttonVibrate],
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      systemBarHeight: "systemBarHeight",
      appThemeConfig: "appThemeConfig",
    }),
  },
  data: () => ({}),
  methods: {
    goBack() {
      this.zzzShort();
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.app-bar {
  z-index: 3;
}
</style>
