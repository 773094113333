<template>
  <div class="pa-5">
    <div class="analyse-title mb-5">最近 7 天</div>
    <v-slide-group v-model="model" class="rounded-lg" center-active>
      <div v-for="(item, index) in stasticData" :key="index">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-slide-item
              class="rounded-lg py-3 overflow-hidden"
              v-slot="{ active, toggle }"
              v-ripple
            >
              <div
                v-bind="attrs"
                v-on="on"
                style="flex-direction: column"
                class="mr-0 px-1 pa-0 d-flex align-center"
                @click="toggle"
              >
                <v-progress-circular
                  rotate="-90"
                  size="45"
                  :value="item.gole"
                  :color="circleColor(item.gole)"
                  ><div style="font-size: 0.7rem">
                    {{ item.gole }}%
                  </div></v-progress-circular
                >
                <div class="mt-1" style="font-size: 0.8rem">
                  {{ dayName(index) }}
                </div>
              </div>
            </v-slide-item>
          </template>
          <div class="tooltip">
            <p>任务完成：{{ item.gole }}%</p>
            <p>时长达成：{{ item.time }}%</p>
            <p>{{ item.date }}</p>
          </div>
        </v-tooltip>
      </div>
    </v-slide-group>
  </div>
</template>

<script>
export default {
  name: "sevenDays",
  props: {
    stasticData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    dayName() {
      return (index) => {
        if (index === 0) {
          return "今天";
        }
        if (index === 1) {
          return "昨天";
        }
        if (index === 2) {
          return "前天";
        }
        return `${index}天前`;
      };
    },
    circleColor() {
      return (num) => {
        if (num < 20) {
          return "deep-orange lighten-2";
        }
        if (num < 40) {
          return "orange lighten-2";
        }
        if (num < 60) {
          return "amber lighten-1";
        }
        if (num < 80) {
          return "lime lighten-1";
        }
        if (num < 95) {
          return "light-green lighten-2";
        }
        return `green lighten-2`;
      };
    },
  },
  data: () => ({
    model: null,
  }),
};
</script>

<style lang="scss" scoped>
::v-deep .v-progress-circular {
  stroke-linecap: round;
}
.analyse-title {
  font-size: 1.3rem;
  font-weight: 700;
}
.tooltip {
  font-size: 0.8rem;
  > p {
    margin: 0;
  }
}
</style>
